<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Adicionar Cliente</h4>

          <div class="page-title-right">
            <b-button
              variant="info"
              @click.prevent="$router.push({ name: 'clientes' })"
            >
              <i class="bx bx-list-ol font-size-16 align-middle mr-2"></i>
              Listar Clientes
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row text-left">
      <div class="col-md-12 col-lg-4">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 mb-3">
                <label class="w-100 cursor-pointer">
                  <div class="uploadimg w-100 border p-5 text-center">
                    <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                    <h6 class="text-muted">clique para enviar imagem</h6>
                  </div>
                  <input
                    type="file"
                    @change="onFileChange"
                    name="image"
                    class="d-none"
                  />
                </label>
              </div>
              <div class="col-12" v-if="img != null">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Imagem</th>
                        <th scope="col" class="text-center">Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="text-center align-middle">
                        <td class="text-left align-middle">
                          <b-img
                            width="100"
                            v-if="img.url && img.url != '' || img.url != null"
                            :src="img.url"
                            rounded
                            alt=""
                          ></b-img>
                          <b-img
                          v-else
                            width="100"
                            src="@/assets/images/notimg.jpg"
                            rounded
                            alt=""
                          ></b-img>
                        </td>
                        <td class="align-middle">
                          <b-button variant="danger" @click.prevent="removerImage()">
                            <i
                              class="bx bx-trash font-size-16 align-middle"
                            ></i>
                          </b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-8">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-3">Dados Pessoais</h5>
            <hr />
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-6 mb-base">
                <label>
                  <span class="mr-1 text-danger">*</span>
                  Nome
                </label>
                <b-form-group>
                  <b-form-input
                    placeholder="Nome completo"
                    for="text"
                    v-model="form.name"
                    :class="{ 'is-invalid': form && $v.form.name.$error }"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 mb-base">
                <label>Status</label>
                <multiselect
                  :options="options_status"
                  class="helo"
                  placeholder="Status"
                  label="text"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  v-model="status"
                  :allow-empty="false"
                ></multiselect>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 mb-base">
                <label>
                  <span class="mr-1 text-danger">*</span>
                  E-mail
                </label>
                <b-form-group>
                  <b-form-input
                    placeholder="E-mail"
                    v-model="form.email"
                    :class="{ 'is-invalid': form && $v.form.email.$error }"
                    for="text"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 mb-base">
                <div class="form-group">
                  <label>Telefone</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.phone"
                    v-mask="'(##) #####-####'"
                    placeholder="Telefone"
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 mb-base">
                <div class="form-group">
                  <label>Data de Nascimento</label>
                  <date-picker
                    format="DD/MM/YYYY"
                    placeholder="Data de nascimento"
                    append-to-body
                    lang="pt_BR"
                    v-model="form.age"
                  ></date-picker>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 mb-base">
                <div class="form-group">
                  <label>
                    CPF
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    v-mask="'###.###.###-##'"
                    v-model="form.cpf"
                    placeholder="CPF"
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 mb-base">
                <div class="form-group">
                  <label>
                    Senha
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="password"
                    :class="{ 'is-invalid': $v.password.$error }"
                    placeholder="Senha"
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 mb-base">
                <div class="form-group">
                  <label>
                    Confirmar Senha
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="repeatPassword"
                    :class="{ 'is-invalid': $v.repeatPassword.$error }"
                    placeholder="Confirmar Senha"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-3">Endereço</h5>
            <hr />
            <div class="row">
              <div class="col-md-12 col-lg-4">
                <label>CEP</label>
                <b-form-group>
                  <b-form-input
                    v-model="form.cep"
                    placeholder="CEP"
                    for="text"
                    v-mask="'#####-###'"
                    v-on:keyup="buscar"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12 col-lg-4">
                <label>Estado</label>
                <multiselect
                  :options="estados"
                  class="helo"
                  placeholder="Estado"
                  label="text"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  v-model="estado"
                  :allow-empty="false"
                ></multiselect>
              </div>
              <div class="col-md-12 col-lg-4">
                <label>Cidade</label>
                <multiselect
                  :options="cidades"
                  class="helo"
                  placeholder="Cidade"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  v-model="form.city"
                  :allow-empty="false"
                ></multiselect>
              </div>
              <div class="col-md-12 col-lg-6">
                <label>Avenida/Rua</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Avenida/Rua"
                    for="text"
                    v-model="form.street"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12 col-lg-6">
                <label>Número</label>
                <b-form-group>
                  <b-form-input
                    v-model="form.number"
                    placeholder="Número"
                    for="text"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12 col-lg-6">
                <label>Bairro</label>
                <b-form-group>
                  <b-form-input
                    v-model="form.district"
                    placeholder="Bairro"
                    for="text"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12 col-lg-6">
                <label>Complemento</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Complemento"
                    v-model="form.complement"
                    for="text"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-3">Endereço de Entrega</h5>
            <hr />
            <div class="row">
              <div class="col-md-12 col-lg-4">
                <label>CEP</label>
                <b-form-group>
                  <b-form-input
                    v-model="form.delivery_cep"
                    placeholder="CEP"
                    for="text"
                    v-mask="'#####-###'"
                    v-on:keyup="buscarentrega"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12 col-lg-4">
                <label>Estado</label>
                <multiselect
                  :options="estados"
                  class="helo"
                  placeholder="Estado"
                  label="text"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  v-model="estado_entrega"
                  :allow-empty="false"
                ></multiselect>
              </div>
              <div class="col-md-12 col-lg-4">
                <label>Cidade</label>
                <multiselect
                  :options="cidades"
                  class="helo"
                  placeholder="Cidade"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  v-model="form.delivery_city"
                  :allow-empty="false"
                ></multiselect>
              </div>
              <div class="col-md-12 col-lg-6">
                <label>Avenida/Rua</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Avenida/Rua"
                    for="text"
                    v-model="form.delivery_street"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12 col-lg-6">
                <label>Número</label>
                <b-form-group>
                  <b-form-input
                    v-model="form.delivery_number"
                    placeholder="Número"
                    for="text"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12 col-lg-6">
                <label>Bairro</label>
                <b-form-group>
                  <b-form-input
                    v-model="form.delivery_district"
                    placeholder="Bairro"
                    for="text"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12 col-lg-6">
                <label>Complemento</label>
                <b-form-group>
                  <b-form-input
                    placeholder="Complemento"
                    v-model="form.delivery_complement"
                    for="text"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-right">
                <b-button variant="success" @click.prevent="salvarData()">
                  <i class="bx bx-save font-size-16 align-middle mr-2"></i>
                  Salvar
                </b-button>
                <b-button
                  variant="danger"
                  class="ml-2"
                  @click.prevent="$router.push({ name: 'clientes' })"
                >
                  <i class="bx bx-x font-size-16 align-middle mr-2"></i>
                  Cancelar
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'
import DatePicker from 'vue2-datepicker'
import brasil from '@/assets/brasil.json'
import axios from 'axios'
import { required, email, sameAs } from 'vuelidate/lib/validators'
export default {
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      password: '',
      repeatPassword: '',
      status: {value: 0, text:"Ativo"},
      options_status: [
        {value: 0, text:"Ativo"},
        {value: 1, text:"Bloqueado"},
      ],
      img: null,
      cidades: [],
      estado: null,
      estado_entrega: null,
      estados: [
        { value: null, text: 'Selecione um estado' },
        { value: 'AC', text: 'Acre' },
        { value: 'AL', text: 'Alagoas' },
        { value: 'AP', text: 'Amapá' },
        { value: 'AM', text: 'Amazonas' },
        { value: 'BA', text: 'Bahia' },
        { value: 'CE', text: 'Ceará' },
        { value: 'DF', text: 'Distrito Federal' },
        { value: 'ES', text: 'Espírito Santo' },
        { value: 'GO', text: 'Goiás' },
        { value: 'MA', text: 'Maranhão' },
        { value: 'MT', text: 'Mato Grosso' },
        { value: 'MS', text: 'Mato Grosso do Sul' },
        { value: 'MG', text: 'Minas Gerais' },
        { value: 'PA', text: 'Pará' },
        { value: 'PB', text: 'Paraíba' },
        { value: 'PR', text: 'Paraná' },
        { value: 'PE', text: 'Pernambuco' },
        { value: 'PI', text: 'Piauí' },
        { value: 'RJ', text: 'Rio de Janeiro' },
        { value: 'RN', text: 'Rio Grande do Norte' },
        { value: 'RS', text: 'Rio Grande do Sul' },
        { value: 'RO', text: 'Rondônia' },
        { value: 'RR', text: 'Roraima' },
        { value: 'SC', text: 'Santa Catarina' },
        { value: 'SP', text: 'São Paulo' },
        { value: 'SE', text: 'Sergipe' },
        { value: 'TO', text: 'Tocantins' },
      ],
      form: {
        name: null,
        email: null,
        cpf: '',
        phone: '',
        city: '',
        street: '',
        district: '',
        number: '',
        cep: '',
        complement: '',
        delivery_complement: '',
        delivery_city: '',
        delivery_street: '',
        delivery_district: '',
        delivery_number: '',
        delivery_cep: '',
      },
    }
  },
  validations: {
    form: {
      name: { required },
      email: { required, email },
    },
    password: {
      required,
    },
    repeatPassword: {
      sameAsPassword: sameAs('password'),
    },
  },
  watch: {
    estado: function () {
      if (this.estado != '') {
        this.cidades = brasil[this.estado.value].cidades
      }
    },
  },
  methods: {
    ...mapActions('customer', ['ActionCustomerCreate']),
    salvarData() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitCustomer()
      }
    },
    async submitCustomer() {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Salvando Dados',
          icon: 'info',
          showConfirmButton: false,
        })
        let data = new FormData()
        if (this.estado != null) {
          data.append('state', this.estado.value)
        }

        if (this.estado_entrega != null) {
          data.append('delivery_state', this.estado_entrega.value)
        }

        if (this.img != null && this.img.file != null) {
          data.append('image', this.img.file)
        }

        data.append('status', this.status.value)
        data.append('name', this.form.name)
        data.append('cpf', this.form.cpf)
        data.append('phone', this.form.phone)
        data.append('city', this.form.city)
        data.append('street', this.form.street)
        data.append('district', this.form.district)
        data.append('number', this.form.number)
        data.append('cep', this.form.cep)
        data.append('email', this.form.email)
        data.append('complement', this.form.complement)
        data.append('delivery_complement', this.form.delivery_complement)
        data.append('delivery_city', this.form.delivery_city)
        data.append('delivery_street', this.form.delivery_street)
        data.append('delivery_district', this.form.delivery_district)
        data.append('delivery_number', this.form.delivery_number)
        data.append('delivery_cep', this.form.delivery_cep)
        data.append('age', this.form.age)
        if (this.password != '') {
          data.append('password', this.password)
        }
        
        await this.ActionCustomerCreate(data)

        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        }).then((_) => {
          this.$router.push({ name: 'clientes' })
        })
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
        })
      }
    },
    onFileChange(e) {
      const file = e.target.files[0]
      this.img = {
        file: file,
        url: URL.createObjectURL(file),
      }
    },
    removerImage(){
      this.img = null
    },
    buscar: function () {
      if (/^[0-9]{5}-[0-9]{3}$/.test(this.form.cep)) {
        axios
          .get('https://viacep.com.br/ws/' + this.form.cep + '/json/')
          .then((response) => {
            this.form.state = response.data.uf
            this.estado = { value: response.data.uf, text: response.data.uf }
            this.form.city = response.data.localidade
            this.form.street = response.data.logradouro
            this.form.district = response.data.bairro
          })
      }
    },
     buscarentrega: function () {
      if (/^[0-9]{5}-[0-9]{3}$/.test(this.form.delivery_cep)) {
        axios
          .get('https://viacep.com.br/ws/' + this.form.delivery_cep + '/json/')
          .then((response) => {
            this.form.delivery_state = response.data.uf
            this.estado_entrega = { value: response.data.uf, text: response.data.uf }
            this.form.delivery_city = response.data.localidade
            this.form.delivery_street = response.data.logradouro
            this.form.delivery_district = response.data.bairro
          })
      }
    },
  },
}
</script>
