<template>
  <div>
      <Customer/>
  </div>
</template>

<script>
import Customer from './components/Customer'
export default {
  components: {
    Customer,
  },
  data() {
    return {
    }
  },
}
</script>
